<template>
  <nav class="bg-gray-800">
    <div class="max-w-7xl sm:px-6 lg:px-8 px-2 mx-auto">
      <div class="relative flex items-center justify-between h-16">
        <div class="flex items-center flex-1">
          <div class="flex items-center flex-shrink-0 text-lg font-semibold text-gray-200">
            <Link href="/" class="hover:contrast-125 hover:brightness-125 transition-all">
              <img :src="logo" class="w-auto h-8" :alt="settings.name" />
            </Link>
          </div>
          <div class="sm:block sm:ml-3 self-end hidden">
            <span class="md:flex hidden px-2 space-x-4 font-mono text-sm text-gray-200">
              {{ slogan }}
            </span>
          </div>
        </div>
        <div class="sm:static sm:inset-auto sm:ml-6 sm:pr-0 absolute inset-y-0 right-0 md:flex items-center pr-2 hidden">
          <Button to="/arbeitgeber" variant="dark" size="sm" :active="isActive('/arbeitgeber')">
            {{ $t("components.navbar.anzeige_schalten") }}
          </Button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { Link } from "@inertiajs/inertia-vue3"
import { Button } from "@/elements"
import { computed } from "vue"
import { logo } from '@/utils/logos'
import settings from "@/generated/settings.json"

const slogan = settings.slogan_de

const isActive = (path: string) => {
  return window && path === window.location.pathname
}
// const activeClasses = "ring-2 ring-offset-2 ring-offset-gray-800 ring-white"
</script>
